import React, { useState } from "react";
import linkedIn from "../../img/in.png";
import { isLangEn } from "../helpers/localization";
import PopUpPolicy from "../Popup/PopUpPolicy";
import PopUpTerm from "../Popup/PopUpTerm";

import styles from "./Footer.module.scss";

const Footer = () => {
  const [isModal, setModal] = useState(false);
  const [isModalTerm, setModalTerm] = useState(false);
  const onClose = () => setModal(false);
  const onCloseTerm = () => setModalTerm(false);

  return (
    <>
      <footer className={styles.wrapper} id="contact">
        <div className={styles.contacts}>
          <div className={styles.license}>
            {isLangEn() && (
              <>
                <div
                  className={styles.link}
                  rel="noreferrer"
                  onClick={() => setModal(true)}
                >
                  Privacy and Personal Data Protection Policy
                </div>
                <br />
                <div
                  className={styles.link}
                  rel="noreferrer"
                  onClick={() => setModalTerm(true)}
                >
                  Terms and Conditions of Website Use
                </div>
                <br />
                <br />
                21+ KETHEA helpline: 2109237777. Competent Regulator EEEP
                <br />
                Frequent participation in games exposes participants to the risk
                of addiction & loss of property
              </>
            )}
            {!isLangEn() && (
              <>
                <div
                  className={styles.link}
                  rel="noreferrer"
                  onClick={() => setModal(true)}
                >
                  Πολιτική Απορρήτου και Προστασίας Προσωπικών Δεδομένων
                </div>
                <br />
                <div
                  className={styles.link}
                  rel="noreferrer"
                  onClick={() => setModalTerm(true)}
                >
                  Όροι και Προϋποθέσεις για την χρήση της ιστοσελίδας
                </div>
                <br />
                <br />
                21+ Γραμμή βοήθειας ΚΕΘΕΑ: 2109237777. Αρμόδιος Ρυθμιστής ΕΕΕΠ
                <br />Η συχνή συμμετοχή στα παίγνια εκθέτει τους συμμετέχοντες
                στον κίνδυνο εθισμού & απώλειας περιουσίας
              </>
            )}
          </div>
        </div>
        <div className={styles.logoLinkedIn}>
          <a
            href="https://www.linkedin.com/company/plumo"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedIn} alt="in" className={styles.linkedIn} />
          </a>
        </div>
        <div className={styles.copyright}>
          <div className={styles.block}>
            {isLangEn() && "Contact"}
            {!isLangEn() && "Επικοινωνία"}:{" "}
            <a href="mailto:info@plumo.com.cy" className={styles.link}>
              info@plumo.com.cy
            </a>
            <br />
          </div>
          Copyright: Plumo 2022
        </div>
      </footer>
      {isLangEn() && (
        <>
          {isModal && (
            <PopUpPolicy lang="en" visible={isModal} onClose={onClose} />
          )}
          {isModalTerm && (
            <PopUpTerm
              lang="en"
              visible={isModalTerm}
              onCloseTerm={onCloseTerm}
            />
          )}
        </>
      )}
      {!isLangEn() && (
        <>
          {isModal && (
            <PopUpPolicy lang="el" visible={isModal} onClose={onClose} />
          )}
          {isModalTerm && (
            <PopUpTerm
              lang="el"
              visible={isModalTerm}
              onCloseTerm={onCloseTerm}
            />
          )}
        </>
      )}
    </>
  );
};

export default Footer;
