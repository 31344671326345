const LANG_EN = "/en";
const EL_LANG_LINK = "/";
const DEFAULT_LOCALE = EL_LANG_LINK;

const getLang = () => {
  if (typeof window !== `undefined`) {
    let currentLocation = window.location.pathname;
    if (currentLocation.slice(-1) === "/") {
      const location = currentLocation.substring(0, currentLocation.length - 1);
      return location;
    }
    return currentLocation;
  }
  return DEFAULT_LOCALE;
};

export const isLangEn = () => getLang() === LANG_EN;
export const isLangEl = () => getLang() === EL_LANG_LINK;
