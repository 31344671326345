import React from "react";
import closeImg from "../../img/close.png";
import { isLangEn } from "../helpers/localization";

import styles from "./popup.module.scss";

const PopUpTerm = ({ lang, visible = false, onCloseTerm }) => {
  console.log("isLangEn", isLangEn());

  if (!visible) return null;

  return (
    <>
      <div className={styles.popup__bg}>
        <div className={styles.popup}>
          <img
            src={closeImg}
            className={styles.close_popup}
            onClick={onCloseTerm}
            alt="Close"
            aria-hidden="true"
          />
          <div className={styles.wrapper_popup}>
            {isLangEn() && (
              <>
                <h3>Terms and Conditions of Website Use</h3>
                <p>Last Document Control Change Date: 15/04/2022</p>

                <h4>1. Introduction</h4>

                <p>
                  1.1 This website is operated by Plumo Limited, of registered
                  address: Evagorou 31, 2nd floor, office 21, 1066 Nicosia,
                  Cyprus (&ldquo;Plumo Ltd&rdquo;, &ldquo;We&rdquo;,
                  &ldquo;Us&rdquo;, &ldquo;the Company&rdquo;).
                </p>

                <p>
                  1.2 Any user of Plumo Ltd&rsquo;s website accepts
                  unreservedly, by using the site, the terms and conditions for
                  its use that are set out here. These terms include the legal
                  jurisdiction within which any legal issues arising from the
                  operation of the site may be addressed. Any person who does
                  not wish to accept these terms and conditions or who knows, or
                  ought to know, of any reason why these terms and conditions
                  might not be capable of applying to their purposes, is not
                  authorized to use this site. These terms apply to each and
                  every visit that Users may make to this site.
                </p>

                <h4>
                  2 Collection and Processing of User Information on Plumo
                  Ltd&rsquo;s websites
                </h4>

                <p>
                  2.1 Plumo Ltd&rsquo;s websites do not automatically capture or
                  store personal information, other than information that is
                  automatically logged about visitors and which is used to
                  produce statistics about the use of the site. The following
                  types of information are automatically collected and stored
                  about any visit:
                </p>

                <ol>
                  <li>
                    The Internet domain and Internet Protocol (IP) address from
                    which you access the site;
                  </li>
                  <li>
                    The type of Internet browser and the operating system of the
                    computer you use to access the site;
                  </li>
                  <li>The date and time you visit the site;</li>
                  <li>The pages you visit on the site;</li>
                  <li>
                    If you came to our site from another website, the address of
                    that website;
                  </li>
                  <li>
                    If you came to the site from a search engine, the address of
                    that search engine and the search term you used.
                  </li>
                  <li>Other technical network data.</li>
                </ol>

                <p>
                  This information is collected by our web server and is only
                  used for system administration and to provide statistics we
                  use to evaluate use of our site.
                </p>

                <p>
                  2.2 Our customers may be asked for personal information if
                  they wish to use our website services (such as our
                  newsletters, web forum, etc.) or if they make a general
                  inquiry or ask us to contact them). In each case, we will only
                  use the personal information they provide to deliver the
                  services they have told us they wish to use. We will not
                  provide information of our customer to any other organization
                  other than within Plumo Ltd/Group, except cases where it is
                  needed for the purposes of providing you our services. In such
                  cases we will additionally inform the customer and ask them
                  for their consent.
                </p>

                <h4>3. Cookie Policy</h4>

                <p>
                  3.1 Currently we do not use Cookies on our websites by
                  default.
                </p>

                <p>
                  3.2 In event of changing that, we will update our Cookies
                  Policy in accordance with the GDPR Cookie Guidelines and local
                  Data Protection Authorities Guidelines on each website, and
                  provide our website visitors with appropriate information
                  about the Cookies in use and options for giving their informed
                  consent or refusal to use of Cookies.
                </p>

                <p>
                  3.3 Please refer to Privacy Policies published on each
                  specific website for obtaining the actual information about
                  the usage of Cookies on that specific website as some of our
                  products can have their own Privacy Policies with additional
                  requirements and statements.
                </p>

                <h4>4. Security</h4>

                <p>
                  4.1 In order to ensure that this website remains available to
                  all users, Plumo Ltd may monitor network traffic to identify
                  unauthorized attempts to upload or change information or to
                  otherwise cause damage to the site. Anyone using this site
                  expressly consents to such monitoring.
                </p>

                <p>
                  4.2 Unauthorized attempts to modify, alter, deface, destroy or
                  corrupt any information stored on this site or this system, to
                  defeat or circumvent any security features, to probe, scan or
                  test for vulnerabilities, to breach security or authentication
                  measures, to forge TCP/IP headers, to install or attempt to
                  install unauthorized software, to mount Denial of Service
                  attacks or to utilize this system for other than its intended
                  purposes are expressly prohibited and may result in criminal
                  prosecution.
                </p>

                <p>
                  4.3 Any possible criminal activity will be reported, together
                  with any evidence which may be gathered, to the appropriate
                  authorities.
                </p>

                <h4>5. Disclaimer of Liability</h4>

                <p>
                  5.1 Every effort is made to provide useful, accurate and
                  complete information. However, we cannot guarantee that there
                  are no errors. Plumo Ltd makes no claims, promises or
                  guarantees about the accuracy, completeness, usefulness or
                  adequacy of the contents of this site and expressly disclaims
                  liability of any sort for errors and omissions in the contents
                  of this site.
                </p>

                <p>
                  5.2 Neither Plumo Ltd, nor its Employees (Staff), associates
                  nor contractors make any warranty, expressed, implied or
                  statutory, including, but not limited to, any warranty that
                  third party rights or title have not been infringed, or any
                  warranty of merchantability or fitness for any particular
                  purpose.
                </p>

                <p>
                  No warranty of any sort is made with respect to the content of
                  third party sites that have links from this site and all
                  liability of every sort is expressly disclaimed.
                </p>

                <p>
                  5.3 Plumo Ltd is not liable in any circumstances for special,
                  indirect or consequential damages or any damages whatsoever
                  resulting from loss of use, loss of data or loss of revenues
                  or profits, whether in an action of contract, negligence or
                  otherwise, arising out of or in connection with any use of the
                  information or services available on this site.
                </p>

                <h4>6. Disclaimer of Endorsement</h4>

                <p>
                  6.1 This site, and documents posted on it, may contain
                  hypertext links or pointers to information created and
                  maintained by other public and private organizations. Plumo
                  Ltd does not guarantee the accuracy, relevance, usefulness,
                  timeliness, or completeness of any linked information.
                </p>

                <p>
                  6.2 The inclusion of links or pointers to other sites is not
                  intended to assign importance to those sites and the
                  information contained in them, nor is it intended to endorse,
                  recommend, or favor any views expressed, or commercial
                  products or services offered on those sites, or the
                  organizations sponsoring the sites, by trade name, trademark,
                  manufacture, or otherwise.
                </p>

                <p>
                  6.3 Reference on this site to any specific commercial
                  products, processes, or services, or the use of any trade,
                  firm or corporation name is for the information and
                  convenience of the site&#39;s visitors, and does not
                  constitute endorsement, recommendation, or favoring by Plumo
                  Ltd.
                </p>

                <p>
                  6.4 The views expressed by any individual within the web forum
                  are the views of that individual only and do not reflect or
                  represent in any way the views of Plumo Ltd.
                </p>

                <h4>7. Copyright </h4>

                <p>
                  7.1 All content on this site, and all content of any documents
                  provided to visitors or clients (in, for instance,
                  newsletters) is the property of Plumo Ltd, unless stated
                  otherwise.
                </p>

                <p>
                  7.2 No user may copy, modify, publish, transmit, transfer or
                  sell, reproduce, create derivative works from, distribute,
                  display, or in any way exploit any of the content, in whole or
                  in part, except with the express written agreement of Plumo
                  Ltd.
                </p>

                <h4>8. Free services </h4>

                <p>
                  8.1 Plumo Ltd provides a number of free services from its
                  site, such as weekly newsletters, white papers, etc. There is
                  no contract with Plumo Ltd for any free service, so no user
                  can become a client by using any free service and Plumo Ltd is
                  not liable to any user in any way resulting from use of any
                  free service.
                </p>

                <h4>9. Products and services purchased from Plumo Ltd </h4>

                <p>
                  9.1 Plumo Ltd makes no representation and gives no warranty
                  with respect to any of the products or services provided by
                  Plumo Ltd. It does not suggest any product or service is
                  suitable for any particular use.
                </p>

                <p>
                  9.2 Documents may include technical inaccuracies or
                  typographical errors.
                </p>

                <h4>10. Severability </h4>

                <p>
                  10.1 If any of these terms are at any time held in any
                  jurisdiction to be void, invalid or unenforceable, then it
                  shall be treated as changed or reduced, only to the extent
                  minimally necessary to bring it within the laws of that
                  jurisdiction and to prevent it from being void, and it shall
                  be binding in that changed or reduced form. Subject to that,
                  each provision shall be interpreted as severable and shall not
                  in any way affect any other of these terms.
                </p>

                <h4>11. No Waiver </h4>

                <p>
                  11.1 No waiver by Plumo Ltd, in exercising any right, power or
                  provision hereunder shall operate as a waiver of any other
                  right or of that same right at a future time; nor shall any
                  delay in exercise of any power or right be interpreted as a
                  waiver.
                </p>

                <h4>12. Governing Law </h4>

                <p>
                  12.1 These terms shall be governed by and construed in
                  accordance with the laws of EU and England and the user
                  explicitly accepts that only the law courts of EU and England
                  have jurisdiction to deal with any matter arising from or in
                  any way, whether directly or indirectly, related the use of
                  this website and, accordingly, the user explicitly waives all
                  and any rights to bring any action of any sort in relation to
                  this web site in any court anywhere else in the world.
                </p>
              </>
            )}
            {!isLangEn() && (
              <>
                <h3>
                  Ό&rho;&omicron;&iota; &kappa;&alpha;&iota;
                  &Pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
                  &gamma;&iota;&alpha; &tau;&eta;&nu; &chi;&rho;ή&sigma;&eta;
                  &tau;&eta;&sigmaf;
                  &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
                </h3>
                <p>
                  &Tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&omicron;&sigmaf;
                  έ&lambda;&epsilon;&gamma;&chi;&omicron;&sigmaf;,
                  &mu;&epsilon;&tau;ά
                  &alpha;&lambda;&lambda;&alpha;&gamma;ώ&nu;,
                  &tau;&omicron;&upsilon;
                  &epsilon;&gamma;&gamma;&rho;ά&phi;&omicron;&upsilon;:
                  15/04/2022
                </p>
                <h4>1. &Epsilon;&iota;&sigma;&alpha;&gamma;&omega;&gamma;ή</h4>
                <p>
                  1.1 &Eta;
                  &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
                  &alpha;&upsilon;&tau;ή
                  (&delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό&sigmaf;
                  &tau;ό&pi;&omicron;&sigmaf;)
                  &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&epsilon;ί
                  &upsilon;&pi;ό &tau;&eta;&nu; Plumo Limited, &mu;&epsilon;
                  έ&delta;&rho;&alpha;:
                  &Epsilon;&upsilon;&alpha;&gamma;ό&rho;&omicron;&upsilon; 31,
                  2&omicron;&sigmaf; ό&rho;&omicron;&phi;&omicron;&sigmaf;,
                  &gamma;&rho;&alpha;&phi;&epsilon;ί&omicron; 21, 1066
                  &Lambda;&epsilon;&upsilon;&kappa;&omega;&sigma;ί&alpha;,
                  &Kappa;ύ&pi;&rho;&omicron;&sigmaf; (&quot;Plumo Ltd&quot;,
                  &quot;&Epsilon;&mu;&epsilon;ί&sigmaf;&quot;,
                  &quot;&Mu;&alpha;&sigmaf;&quot;, &quot;&eta;
                  &Epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&quot;).
                </p>
                <p>
                  1.2 &Kappa;ά&theta;&epsilon;
                  &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &tau;&eta;&sigmaf;
                  &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
                  &tau;&eta;&sigmaf; Plumo Ltd
                  &alpha;&pi;&omicron;&delta;έ&chi;&epsilon;&tau;&alpha;&iota;
                  &alpha;&nu;&epsilon;&pi;&iota;&phi;ύ&lambda;&alpha;&kappa;&tau;&alpha;,
                  &mu;&epsilon; &tau;&eta; &chi;&rho;ή&sigma;&eta;
                  &tau;&eta;&sigmaf;, &tau;&omicron;&upsilon;&sigmaf;
                  ό&rho;&omicron;&upsilon;&sigmaf; &kappa;&alpha;&iota;
                  &tau;&iota;&sigmaf;
                  &pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
                  &pi;&omicron;&upsilon;
                  &pi;&alpha;&rho;&alpha;&tau;ί&theta;&epsilon;&nu;&tau;&alpha;&iota;
                  &epsilon;&delta;ώ. &Alpha;&upsilon;&tau;&omicron;ί
                  &omicron;&iota; ό&rho;&omicron;&iota;
                  &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&nu;
                  &tau;&eta;&nu; &alpha;&rho;&mu;ό&delta;&iota;&alpha;
                  &nu;&omicron;&mu;&iota;&kappa;ή
                  &delta;&iota;&kappa;&alpha;&iota;&omicron;&delta;&omicron;&sigma;ί&alpha;
                  &gamma;&iota;&alpha; &tau;&eta;&nu;
                  &alpha;&nu;&tau;&iota;&mu;&epsilon;&tau;ώ&pi;&iota;&sigma;&eta;
                  &tau;&upsilon;&chi;ό&nu; &nu;&omicron;&mu;&iota;&kappa;ώ&nu;
                  &zeta;&eta;&tau;&eta;&mu;ά&tau;&omega;&nu;
                  &pi;&omicron;&upsilon;
                  &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota;
                  &nu;&alpha;
                  &alpha;&nu;&alpha;&kappa;ύ&psi;&omicron;&upsilon;&nu;
                  &alpha;&pi;ό &tau;&eta;
                  &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;ί&alpha;
                  &tau;&omicron;&upsilon;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;.
                  Ό&pi;&omicron;&iota;&omicron;&sigmaf; &delta;&epsilon;&nu;
                  &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί &nu;&alpha;
                  &alpha;&pi;&omicron;&delta;&epsilon;&chi;&theta;&epsilon;ί
                  &tau;&omicron;&upsilon;&sigmaf;
                  &pi;&alpha;&rho;ό&nu;&tau;&epsilon;&sigmaf;
                  ό&rho;&omicron;&upsilon;&sigmaf; &kappa;&alpha;&iota;
                  &pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;,
                  ή &gamma;&nu;&omega;&rho;ί&zeta;&epsilon;&iota;, ή
                  &omicron;&phi;&epsilon;ί&lambda;&epsilon;&iota; &nu;&alpha;
                  &gamma;&nu;&omega;&rho;ί&zeta;&epsilon;&iota;
                  &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &lambda;ό&gamma;&omicron; &gamma;&iota;&alpha;
                  &tau;&omicron;&nu; &omicron;&pi;&omicron;ί&omicron;
                  &omicron;&iota; &pi;&alpha;&rho;ό&nu;&tau;&epsilon;&sigmaf;
                  ό&rho;&omicron;&iota; &kappa;&alpha;&iota;
                  &pi;&rho;&omicron;ϋ&pi;&omicron;&theta;έ&sigma;&epsilon;&iota;&sigmaf;
                  &delta;&epsilon;&nu; &mu;&pi;&omicron;&rho;&omicron;ύ&nu;
                  &nu;&alpha;
                  &epsilon;&phi;&alpha;&rho;&mu;&omicron;&sigma;&tau;&omicron;ύ&nu;
                  &gamma;&iota;&alpha; &tau;&omicron;&upsilon;&sigmaf;
                  &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf;
                  &tau;&omicron;&upsilon;&sigmaf;, &delta;&epsilon;&nu;
                  &epsilon;&pi;&iota;&tau;&rho;έ&pi;&epsilon;&tau;&alpha;&iota;
                  &nu;&alpha;
                  &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
                  &tau;&omicron;&nu; &pi;&alpha;&rho;ό&nu;&tau;&alpha;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;.
                  &Omicron;&iota; &pi;&alpha;&rho;ό&nu;&tau;&epsilon;&sigmaf;
                  ό&rho;&omicron;&iota;
                  &iota;&sigma;&chi;ύ&omicron;&upsilon;&nu; &gamma;&iota;&alpha;
                  &kappa;ά&theta;&epsilon;
                  &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;&eta;
                  &pi;&omicron;&upsilon; &mu;&pi;&omicron;&rho;&epsilon;ί
                  &nu;&alpha;
                  &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;ή&sigma;&omicron;&upsilon;&nu;
                  &omicron;&iota; &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;
                  &sigma;&epsilon; &alpha;&upsilon;&tau;ό&nu; &tau;&omicron;&nu;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;.
                </p>
                <h4>
                  2. &Sigma;&upsilon;&lambda;&lambda;&omicron;&gamma;ή
                  &kappa;&alpha;&iota;
                  &epsilon;&pi;&epsilon;&xi;&epsilon;&rho;&gamma;&alpha;&sigma;ί&alpha;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
                  &chi;&rho;ή&sigma;&tau;&eta; &sigma;&tau;&iota;&sigmaf;
                  &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;
                  &tau;&eta;&sigmaf; Plumo Ltd
                </h4>
                <p>
                  2.1 &Omicron;&iota;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&iota;
                  &tau;&eta;&sigmaf; Plumo Ltd &delta;&epsilon;&nu;
                  &kappa;&alpha;&tau;&alpha;&gamma;&rho;ά&phi;&omicron;&upsilon;&nu;
                  &omicron;ύ&tau;&epsilon;
                  &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;ύ&omicron;&upsilon;&nu;
                  &alpha;&upsilon;&tau;ό&mu;&alpha;&tau;&alpha;
                  &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;έ&sigmaf;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;,
                  &epsilon;&kappa;&tau;ό&sigmaf; &alpha;&pi;ό
                  &tau;&iota;&sigmaf;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
                  &pi;&omicron;&upsilon;
                  &kappa;&alpha;&tau;&alpha;&gamma;&rho;ά&phi;&omicron;&nu;&tau;&alpha;&iota;
                  &alpha;&upsilon;&tau;ό&mu;&alpha;&tau;&alpha;
                  &kappa;&alpha;&iota; &alpha;&phi;&omicron;&rho;&omicron;ύ&nu;
                  &tau;&omicron;&upsilon;&sigmaf;
                  &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigmaf;,
                  &omicron;&iota; &omicron;&pi;&omicron;ί&epsilon;&sigmaf;
                  &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
                  &gamma;&iota;&alpha; &tau;&eta;&nu;
                  &pi;&alpha;&rho;&alpha;&gamma;&omega;&gamma;ή
                  &sigma;&tau;&alpha;&tau;&iota;&sigma;&tau;&iota;&kappa;ώ&nu;
                  &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu;
                  &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
                  &tau;&eta; &chi;&rho;ή&sigma;&eta; &tau;&omicron;&upsilon;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;.
                  &Omicron;&iota;
                  &alpha;&kappa;ό&lambda;&omicron;&upsilon;&theta;&omicron;&iota;
                  &tau;ύ&pi;&omicron;&iota;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
                  &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&nu;&tau;&alpha;&iota;
                  &kappa;&alpha;&iota;
                  &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
                  &alpha;&upsilon;&tau;ό&mu;&alpha;&tau;&alpha;
                  &gamma;&iota;&alpha; &kappa;ά&theta;&epsilon;
                  &epsilon;&pi;ί&sigma;&kappa;&epsilon;&psi;&eta;:
                </p>
                <p>
                  1. &Omicron; &tau;&omicron;&mu;έ&alpha;&sigmaf;
                  &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;ύ&omicron;&upsilon;
                  (Internet domain)&nbsp; &kappa;&alpha;&iota; &eta;
                  &delta;&iota;&epsilon;ύ&theta;&upsilon;&nu;&sigma;&eta;
                  &pi;&rho;&omega;&tau;&omicron;&kappa;ό&lambda;&lambda;&omicron;&upsilon;
                  &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;ύ&omicron;&upsilon;
                  (IP) &alpha;&pi;ό &tau;&eta;&nu;
                  &omicron;&pi;&omicron;ί&alpha;
                  &alpha;&pi;&omicron;&kappa;&tau;ά&tau;&epsilon;
                  &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta;
                  &sigma;&tau;&omicron;&nu;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;,
                </p>
                <p>
                  2. &Omicron; &tau;ύ&pi;&omicron;&sigmaf;
                  &tau;&omicron;&upsilon;
                  &pi;&rho;&omicron;&gamma;&rho;ά&mu;&mu;&alpha;&tau;&omicron;&sigmaf;
                  &pi;&epsilon;&rho;&iota;ή&gamma;&eta;&sigma;&eta;&sigmaf;
                  &sigma;&tau;&omicron;
                  &Delta;&iota;&alpha;&delta;ί&kappa;&tau;&upsilon;&omicron;
                  &kappa;&alpha;&iota; &tau;&omicron;
                  &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&iota;&kappa;ό
                  &sigma;ύ&sigma;&tau;&eta;&mu;&alpha; &tau;&omicron;&upsilon;
                  &upsilon;&pi;&omicron;&lambda;&omicron;&gamma;&iota;&sigma;&tau;ή
                  &pi;&omicron;&upsilon;
                  &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&epsilon;
                  &gamma;&iota;&alpha; &tau;&eta;&nu;
                  &pi;&rho;ό&sigma;&beta;&alpha;&sigma;&eta;
                  &sigma;&tau;&omicron;&nu;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;,
                </p>
                <p>
                  3. &Eta; &eta;&mu;&epsilon;&rho;&omicron;&mu;&eta;&nu;ί&alpha;
                  &kappa;&alpha;&iota; ώ&rho;&alpha; &pi;&omicron;&upsilon;
                  &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigma;&tau;&epsilon;
                  &tau;&omicron;&nu;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;,
                </p>
                <p>
                  4. &Omicron;&iota;
                  &sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;
                  &pi;&omicron;&upsilon;
                  &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigma;&tau;&epsilon;
                  &sigma;&tau;&omicron;&nu;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;,
                </p>
                <p>
                  5. &Epsilon;ά&nu; ή&rho;&theta;&alpha;&tau;&epsilon;
                  &sigma;&tau;&omicron;&nu;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;ό &mu;&alpha;&sigmaf;
                  &alpha;&pi;ό ά&lambda;&lambda;&omicron;&nu;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
                  &kappa;&alpha;&iota; &tau;&eta;
                  &delta;&iota;&epsilon;ύ&theta;&upsilon;&nu;&sigma;&eta;
                  &tau;&omicron;&upsilon; &epsilon;&nu; &lambda;ό&gamma;&omega;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;,
                </p>
                <p>
                  6. &Epsilon;ά&nu; ή&rho;&theta;&alpha;&tau;&epsilon;
                  &sigma;&tau;&omicron;&nu;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; &alpha;&pi;ό
                  &mu;&eta;&chi;&alpha;&nu;ή
                  &alpha;&nu;&alpha;&zeta;ή&tau;&eta;&sigma;&eta;&sigmaf;,
                  &tau;&eta;
                  &delta;&iota;&epsilon;ύ&theta;&upsilon;&nu;&sigma;&eta;
                  &tau;&eta;&sigmaf; &epsilon;&nu; &lambda;ό&gamma;&omega;
                  &mu;&eta;&chi;&alpha;&nu;ή&sigmaf;
                  &alpha;&nu;&alpha;&zeta;ή&tau;&eta;&sigma;&eta;&sigmaf;
                  &kappa;&alpha;&iota; &tau;&omicron;&nu; ό&rho;&omicron;
                  &alpha;&nu;&alpha;&zeta;ή&tau;&eta;&sigma;&eta;&sigmaf;
                  &pi;&omicron;&upsilon;
                  &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&alpha;&tau;&epsilon;.
                </p>
                <p>
                  7. Ά&lambda;&lambda;&alpha;
                  &tau;&epsilon;&chi;&nu;&iota;&kappa;ά
                  &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;
                  &delta;&iota;&kappa;&tau;ύ&omicron;&upsilon;.
                </p>
                <p>
                  &Alpha;&upsilon;&tau;έ&sigmaf; &omicron;&iota;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
                  &sigma;&upsilon;&lambda;&lambda;έ&gamma;&omicron;&nu;&tau;&alpha;&iota;
                  &alpha;&pi;ό &tau;&omicron;&nu;
                  &delta;&iota;&alpha;&kappa;&omicron;&mu;&iota;&sigma;&tau;ή
                  &iota;&sigma;&tau;&omicron;ύ &mu;&alpha;&sigmaf;
                  &kappa;&alpha;&iota;
                  &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
                  &mu;ό&nu;&omicron; &gamma;&iota;&alpha; &tau;&eta;
                  &delta;&iota;&alpha;&chi;&epsilon;ί&rho;&iota;&sigma;&eta;
                  &tau;&omicron;&upsilon;
                  &sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&omicron;&sigmaf;
                  &kappa;&alpha;&iota; &gamma;&iota;&alpha; &tau;&eta;&nu;
                  &pi;&alpha;&rho;&omicron;&chi;ή
                  &sigma;&tau;&alpha;&tau;&iota;&sigma;&tau;&iota;&kappa;ώ&nu;
                  &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu;
                  &pi;&omicron;&upsilon;
                  &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
                  &gamma;&iota;&alpha; &tau;&eta;&nu;
                  &alpha;&xi;&iota;&omicron;&lambda;ό&gamma;&eta;&sigma;&eta;
                  &tau;&eta;&sigmaf; &chi;&rho;ή&sigma;&eta;&sigmaf;
                  &tau;&omicron;&upsilon;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;ύ
                  &mu;&alpha;&sigmaf;.
                </p>
                <p>
                  2.2. &Epsilon;ά&nu; &omicron;&iota;
                  &pi;&epsilon;&lambda;ά&tau;&epsilon;&sigmaf;
                  &mu;&alpha;&sigmaf;
                  &epsilon;&pi;&iota;&theta;&upsilon;&mu;&omicron;ύ&nu;
                  &nu;&alpha;
                  &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&omicron;&upsilon;&nu;
                  &tau;&iota;&sigmaf;
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
                  &tau;&omicron;&upsilon;
                  &iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
                  &mu;&alpha;&sigmaf; (ό&pi;&omega;&sigmaf; &tau;&alpha;
                  &epsilon;&nu;&eta;&mu;&epsilon;&rho;&omega;&tau;&iota;&kappa;ά
                  &mu;&alpha;&sigmaf; &delta;&epsilon;&lambda;&tau;ί&alpha;,
                  &tau;&omicron;
                  &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό
                  &phi;ό&rho;&omicron;&upsilon;&mu; &kappa;.&lambda;&pi;.) ή
                  &epsilon;ά&nu;
                  &upsilon;&pi;&omicron;&beta;ά&lambda;&omicron;&upsilon;&nu;
                  &mu;&iota;&alpha; &gamma;&epsilon;&nu;&iota;&kappa;ή
                  &epsilon;&rho;ώ&tau;&eta;&sigma;&eta; ή &mu;&alpha;&sigmaf;
                  &zeta;&eta;&tau;ή&sigma;&omicron;&upsilon;&nu; &nu;&alpha;
                  &epsilon;&pi;&iota;&kappa;&omicron;&iota;&nu;&omega;&nu;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
                  &mu;&alpha;&zeta;ί &tau;&omicron;&upsilon;&sigmaf;,
                  &tau;ό&tau;&epsilon; &mu;&pi;&omicron;&rho;&epsilon;ί
                  &nu;&alpha; &tau;&omicron;&upsilon;&sigmaf;
                  &zeta;&eta;&tau;&eta;&theta;&omicron;ύ&nu;
                  &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;έ&sigmaf;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;/&delta;&epsilon;&delta;&omicron;&mu;έ&nu;&alpha;.
                  &Sigma;&epsilon; &kappa;ά&theta;&epsilon;
                  &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;,
                  &theta;&alpha;
                  &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
                  &tau;&iota;&sigmaf;
                  &pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;έ&sigmaf;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
                  &pi;&omicron;&upsilon; &mu;&alpha;&sigmaf;
                  &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&nu;
                  &mu;ό&nu;&omicron; &gamma;&iota;&alpha; &tau;&eta;&nu;
                  &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&eta;
                  &pi;&alpha;&rho;&omicron;&chi;ή
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
                  &pi;&omicron;&upsilon; &mu;&alpha;&sigmaf;
                  έ&chi;&epsilon;&iota; &zeta;&eta;&tau;&eta;&theta;&epsilon;ί.
                  &Delta;&epsilon;&nu; &theta;&alpha;
                  &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&mu;&epsilon;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
                  &tau;&omicron;&upsilon; &pi;&epsilon;&lambda;ά&tau;&eta;
                  &mu;&alpha;&sigmaf; &sigma;&epsilon;
                  &kappa;&alpha;&nu;έ&nu;&alpha;&nu; ά&lambda;&lambda;&omicron;
                  &omicron;&rho;&gamma;&alpha;&nu;&iota;&sigma;&mu;ό
                  &epsilon;&kappa;&tau;ό&sigmaf; &alpha;&pi;ό &tau;&omicron;
                  &epsilon;&sigma;&omega;&tau;&epsilon;&rho;&iota;&kappa;ό
                  &tau;&eta;&sigmaf; Plumo
                  Ltd/&Omicron;&mu;ί&lambda;&omicron;&upsilon;,
                  &epsilon;&kappa;&tau;ό&sigmaf; &alpha;&pi;ό
                  &tau;&iota;&sigmaf;
                  &pi;&epsilon;&rho;&iota;&pi;&tau;ώ&sigma;&epsilon;&iota;&sigmaf;
                  &pi;&omicron;&upsilon; &epsilon;ί&nu;&alpha;&iota;
                  &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&epsilon;&sigmaf;
                  &gamma;&iota;&alpha; &tau;&omicron;&upsilon;&sigmaf;
                  &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf;
                  &tau;&eta;&sigmaf; &pi;&alpha;&rho;&omicron;&chi;ή&sigmaf;
                  &tau;&omega;&nu;
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
                  &mu;&alpha;&sigmaf; &sigma;&epsilon;
                  &epsilon;&sigma;ά&sigmaf;. &Sigma;&epsilon;
                  &alpha;&upsilon;&tau;έ&sigmaf; &tau;&iota;&sigmaf;
                  &pi;&epsilon;&rho;&iota;&pi;&tau;ώ&sigma;&epsilon;&iota;&sigmaf;
                  &theta;&alpha;
                  &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&sigma;&omicron;&upsilon;&mu;&epsilon;
                  &epsilon;&kappa; &nu;έ&omicron;&upsilon; &tau;&omicron;&nu;
                  &pi;&epsilon;&lambda;ά&tau;&eta; &kappa;&alpha;&iota;
                  &theta;&alpha; &tau;&omicron;&upsilon;
                  &zeta;&eta;&tau;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
                  &tau;&eta;
                  &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;ή
                  &tau;&omicron;&upsilon;.
                </p>
                <h4>
                  3. &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή Cookies
                </h4>
                <p>
                  3.1 &Epsilon;&pi;ί &tau;&omicron;&upsilon;
                  &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf;,
                  &delta;&epsilon;&nu;
                  &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;
                  Cookies &sigma;&tau;&iota;&sigmaf;
                  &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&epsilon;&sigmaf;
                  &mu;&alpha;&sigmaf; &epsilon;&xi;
                  &omicron;&rho;&iota;&sigma;&mu;&omicron;ύ.
                </p>
                <p>
                  3.2 &Sigma;&epsilon;
                  &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
                  &pi;&omicron;&upsilon;
                  &alpha;&lambda;&lambda;ά&xi;&epsilon;&iota;
                  &alpha;&upsilon;&tau;ό, &theta;&alpha;
                  &epsilon;&pi;&iota;&kappa;&alpha;&iota;&rho;&omicron;&pi;&omicron;&iota;ή&sigma;&omicron;&upsilon;&mu;&epsilon;
                  &tau;&eta;&nu; &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
                  &mu;&alpha;&sigmaf; &gamma;&iota;&alpha; &tau;&alpha; Cookies
                  &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon;
                  &tau;&iota;&sigmaf;
                  &Omicron;&delta;&eta;&gamma;ί&epsilon;&sigmaf;
                  &gamma;&iota;&alpha; &tau;&alpha; Cookies
                  &tau;&omicron;&upsilon; GDPR &kappa;&alpha;&iota;
                  &tau;&iota;&sigmaf;
                  &Omicron;&delta;&eta;&gamma;ί&epsilon;&sigmaf;
                  &tau;&omega;&nu; &tau;&omicron;&pi;&iota;&kappa;ώ&nu;
                  &Alpha;&rho;&chi;ώ&nu;
                  &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
                  &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;,
                  &sigma;&epsilon; &kappa;ά&theta;&epsilon;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
                  &kappa;&alpha;&iota; &theta;&alpha;
                  &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&mu;&epsilon;
                  &sigma;&tau;&omicron;&upsilon;&sigmaf;
                  &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigmaf;
                  &tau;&omicron;&upsilon;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;ύ
                  &mu;&alpha;&sigmaf; &tau;&iota;&sigmaf;
                  &kappa;&alpha;&tau;ά&lambda;&lambda;&eta;&lambda;&epsilon;&sigmaf;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
                  &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
                  &tau;&alpha;
                  &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&omicron;ύ&mu;&epsilon;&nu;&alpha;
                  Cookies &kappa;&alpha;&iota; &tau;&iota;&sigmaf;
                  &epsilon;&pi;&iota;&lambda;&omicron;&gamma;έ&sigmaf;
                  &tau;&omicron;&upsilon;&sigmaf; &gamma;&iota;&alpha;
                  &nu;&alpha; &delta;ώ&sigma;&omicron;&upsilon;&nu; &tau;&eta;
                  &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;ή
                  &tau;&omicron;&upsilon;&sigmaf; ή &nu;&alpha;
                  &alpha;&rho;&nu;&eta;&theta;&omicron;ύ&nu; &tau;&eta;
                  &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu; Cookies.
                </p>
                <p>
                  3.3
                  &Pi;&alpha;&rho;&alpha;&kappa;&alpha;&lambda;&omicron;ύ&mu;&epsilon;
                  &alpha;&nu;&alpha;&tau;&rho;έ&xi;&tau;&epsilon;
                  &sigma;&tau;&iota;&sigmaf;
                  &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;έ&sigmaf;
                  &Alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;
                  &pi;&omicron;&upsilon;
                  &delta;&eta;&mu;&omicron;&sigma;&iota;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
                  &sigma;&epsilon; &kappa;ά&theta;&epsilon;
                  &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&omicron;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
                  &gamma;&iota;&alpha; &nu;&alpha;
                  &lambda;ά&beta;&epsilon;&tau;&epsilon; &tau;&iota;&sigmaf;
                  &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&iota;&kappa;έ&sigmaf;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
                  &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
                  &tau;&eta; &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu; Cookies
                  &sigma;&tau;&omicron;&nu;
                  &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&omicron;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;,
                  &kappa;&alpha;&theta;ώ&sigmaf;
                  &omicron;&rho;&iota;&sigma;&mu;έ&nu;&alpha; &alpha;&pi;ό
                  &tau;&alpha; &pi;&rho;&omicron;ϊό&nu;&tau;&alpha;
                  &mu;&alpha;&sigmaf; &mu;&pi;&omicron;&rho;&epsilon;ί
                  &nu;&alpha; έ&chi;&omicron;&upsilon;&nu; &tau;&iota;&sigmaf;
                  &delta;&iota;&kappa;έ&sigmaf; &tau;&omicron;&upsilon;&sigmaf;
                  &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;έ&sigmaf;
                  &Alpha;&pi;&omicron;&rho;&rho;ή&tau;&omicron;&upsilon;
                  &mu;&epsilon;
                  &pi;&rho;ό&sigma;&theta;&epsilon;&tau;&epsilon;&sigmaf;
                  &alpha;&pi;&alpha;&iota;&tau;ή&sigma;&epsilon;&iota;&sigmaf;
                  &kappa;&alpha;&iota;
                  &delta;&eta;&lambda;ώ&sigma;&epsilon;&iota;&sigmaf;.
                </p>
                <h4>4. &Alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;</h4>
                <p>
                  4.1
                  &Pi;&rho;&omicron;&kappa;&epsilon;&iota;&mu;έ&nu;&omicron;&upsilon;
                  &nu;&alpha;
                  &delta;&iota;&alpha;&sigma;&phi;&alpha;&lambda;&iota;&sigma;&tau;&epsilon;ί
                  ό&tau;&iota; &omicron; &pi;&alpha;&rho;ώ&nu;
                  &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό&sigmaf;
                  &tau;ό&pi;&omicron;&sigmaf;
                  &pi;&alpha;&rho;&alpha;&mu;έ&nu;&epsilon;&iota;
                  &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&omicron;&sigmaf;
                  &sigma;&epsilon; ό&lambda;&omicron;&upsilon;&sigmaf;
                  &tau;&omicron;&upsilon;&sigmaf;
                  &chi;&rho;ή&sigma;&tau;&epsilon;&sigmaf;, &eta; Plumo Ltd
                  &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
                  &pi;&alpha;&rho;&alpha;&kappa;&omicron;&lambda;&omicron;&upsilon;&theta;&epsilon;ί
                  &tau;&eta;&nu;
                  &kappa;&upsilon;&kappa;&lambda;&omicron;&phi;&omicron;&rho;ί&alpha;
                  &tau;&omicron;&upsilon;
                  &delta;&iota;&kappa;&tau;ύ&omicron;&upsilon;
                  &gamma;&iota;&alpha; &nu;&alpha;
                  &epsilon;&nu;&tau;&omicron;&pi;ί&sigma;&epsilon;&iota;
                  &mu;&eta;
                  &epsilon;&xi;&omicron;&upsilon;&sigma;&iota;&omicron;&delta;&omicron;&tau;&eta;&mu;έ&nu;&epsilon;&sigmaf;
                  &pi;&rho;&omicron;&sigma;&pi;ά&theta;&epsilon;&iota;&epsilon;&sigmaf;
                  &mu;&epsilon;&tau;&alpha;&phi;ό&rho;&tau;&omega;&sigma;&eta;&sigmaf;
                  ή &alpha;&lambda;&lambda;&alpha;&gamma;ή&sigmaf;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
                  ή &mu;&epsilon; ά&lambda;&lambda;&omicron;
                  &tau;&rho;ό&pi;&omicron;
                  &pi;&rho;ό&kappa;&lambda;&eta;&sigma;&eta;&sigmaf;
                  &zeta;&eta;&mu;ί&alpha;&sigmaf; &sigma;&tau;&omicron;&nu;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;.
                  &Omicron;&pi;&omicron;&iota;&omicron;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;&epsilon;ί
                  &alpha;&upsilon;&tau;ό&nu; &tau;&omicron;&nu;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
                  &sigma;&upsilon;&nu;&alpha;&iota;&nu;&epsilon;ί
                  &rho;&eta;&tau;ά &sigma;&tau;&eta;&nu; &epsilon;&nu;
                  &lambda;ό&gamma;&omega;
                  &pi;&alpha;&rho;&alpha;&kappa;&omicron;&lambda;&omicron;ύ&theta;&eta;&sigma;&eta;.
                </p>
                <p>
                  4.2.
                  &Alpha;&pi;&alpha;&gamma;&omicron;&rho;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
                  &rho;&eta;&tau;ά &omicron;&iota; &mu;&eta;
                  &epsilon;&xi;&omicron;&upsilon;&sigma;&iota;&omicron;&delta;&omicron;&tau;&eta;&mu;έ&nu;&epsilon;&sigmaf;
                  &alpha;&pi;ό&pi;&epsilon;&iota;&rho;&epsilon;&sigmaf;
                  &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;&sigmaf;,
                  &alpha;&lambda;&lambda;&alpha;&gamma;ή&sigmaf;,
                  &pi;&alpha;&rho;&alpha;&mu;ό&rho;&phi;&omega;&sigma;&eta;&sigmaf;,
                  &kappa;&alpha;&tau;&alpha;&sigma;&tau;&rho;&omicron;&phi;ή&sigmaf;
                  ή &alpha;&lambda;&lambda;&omicron;ί&omega;&sigma;&eta;&sigmaf;
                  &omicron;&pi;&omicron;&iota;&alpha;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&alpha;&sigmaf;
                  &pi;&omicron;&upsilon; &epsilon;ί&nu;&alpha;&iota;
                  &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;&upsilon;&mu;έ&nu;&eta;
                  &sigma;&epsilon; &alpha;&upsilon;&tau;ό&nu; &tau;&omicron;&nu;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron; ή
                  &sigma;&epsilon; &alpha;&upsilon;&tau;ό &tau;&omicron;
                  &sigma;ύ&sigma;&tau;&eta;&mu;&alpha;, ή
                  &kappa;&alpha;&tau;&alpha;&sigma;&tau;&rho;&alpha;&tau;ή&gamma;&eta;&sigma;&eta;&sigmaf;
                  ή &pi;&alpha;&rho;ά&kappa;&alpha;&mu;&psi;&eta;&sigmaf;
                  &omicron;&pi;&omicron;&iota;&omega;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &chi;&alpha;&rho;&alpha;&kappa;&tau;&eta;&rho;&iota;&sigma;&tau;&iota;&kappa;ώ&nu;
                  &alpha;&sigma;&phi;&alpha;&lambda;&epsilon;ί&alpha;&sigmaf;,
                  &delta;&iota;&epsilon;&rho;&epsilon;ύ&nu;&eta;&sigma;&eta;&sigmaf;,
                  &sigma;ά&rho;&omega;&sigma;&eta;&sigmaf; ή
                  &delta;&omicron;&kappa;&iota;&mu;ή&sigmaf;
                  &gamma;&iota;&alpha;
                  &epsilon;&upsilon;&pi;ά&theta;&epsilon;&iota;&epsilon;&sigmaf;,
                  &pi;&alpha;&rho;&alpha;&beta;ί&alpha;&sigma;&eta;&sigmaf;
                  &mu;έ&tau;&rho;&omega;&nu;
                  &alpha;&sigma;&phi;&alpha;&lambda;&epsilon;ί&alpha;&sigmaf; ή
                  &epsilon;&lambda;έ&gamma;&chi;&omicron;&upsilon;
                  &tau;&alpha;&upsilon;&tau;ό&tau;&eta;&tau;&alpha;&sigmaf;,
                  &pi;&alpha;&rho;&alpha;&pi;&omicron;ί&eta;&sigma;&eta;&sigmaf;
                  &epsilon;&pi;&iota;&kappa;&epsilon;&phi;&alpha;&lambda;ί&delta;&omega;&nu;
                  TCP/IP,
                  &epsilon;&gamma;&kappa;&alpha;&tau;ά&sigma;&tau;&alpha;&sigma;&eta;&sigmaf;
                  ή &alpha;&pi;ό&pi;&epsilon;&iota;&rho;&alpha;&sigmaf;
                  &epsilon;&gamma;&kappa;&alpha;&tau;ά&sigma;&tau;&alpha;&sigma;&eta;&sigmaf;
                  &mu;&eta;
                  &epsilon;&xi;&omicron;&upsilon;&sigma;&iota;&omicron;&delta;&omicron;&tau;&eta;&mu;έ&nu;&omicron;&upsilon;
                  &lambda;&omicron;&gamma;&iota;&sigma;&mu;&iota;&kappa;&omicron;ύ,
                  &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;&sigmaf;
                  &epsilon;&pi;&iota;&theta;έ&sigma;&epsilon;&omega;&nu;
                  ά&rho;&nu;&eta;&sigma;&eta;&sigmaf;
                  &pi;&alpha;&rho;&omicron;&chi;ή&sigmaf;
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu; ή
                  &chi;&rho;ή&sigma;&eta;&sigmaf;
                  &alpha;&upsilon;&tau;&omicron;ύ &tau;&omicron;&upsilon;
                  &sigma;&upsilon;&sigma;&tau;ή&mu;&alpha;&tau;&omicron;&sigmaf;
                  &gamma;&iota;&alpha;
                  &sigma;&kappa;&omicron;&pi;&omicron;ύ&sigmaf;
                  ά&lambda;&lambda;&omicron;&upsilon;&sigmaf; &alpha;&pi;ό
                  &tau;&omicron;&upsilon;&sigmaf;
                  &pi;&rho;&omicron;&beta;&lambda;&epsilon;&pi;ό&mu;&epsilon;&nu;&omicron;&upsilon;&sigmaf;.
                  &Tau;&alpha; &alpha;&nu;&omega;&tau;έ&rho;&omega;
                  &alpha;&pi;&alpha;&gamma;&omicron;&rho;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
                  &rho;&eta;&tau;ά &kappa;&alpha;&iota;
                  &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
                  &omicron;&delta;&eta;&gamma;ή&sigma;&omicron;&upsilon;&nu;
                  &sigma;&epsilon; &pi;&omicron;&iota;&nu;&iota;&kappa;ή
                  &delta;ί&omega;&xi;&eta;.
                </p>
                <p>
                  4.3. &Kappa;ά&theta;&epsilon; &pi;&iota;&theta;&alpha;&nu;ή
                  &epsilon;&gamma;&kappa;&lambda;&eta;&mu;&alpha;&tau;&iota;&kappa;ή
                  &delta;&rho;&alpha;&sigma;&tau;&eta;&rho;&iota;ό&tau;&eta;&tau;&alpha;
                  &theta;&alpha;
                  &alpha;&nu;&alpha;&phi;έ&rho;&epsilon;&tau;&alpha;&iota;,
                  &mu;&alpha;&zeta;ί &mu;&epsilon; &tau;&upsilon;&chi;ό&nu;
                  &alpha;&pi;&omicron;&delta;&epsilon;&iota;&kappa;&tau;&iota;&kappa;ά
                  &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
                  &pi;&omicron;&upsilon; &mu;&pi;&omicron;&rho;&epsilon;ί
                  &nu;&alpha;
                  &sigma;&upsilon;&gamma;&kappa;&epsilon;&nu;&tau;&rho;&omega;&theta;&omicron;ύ&nu;,
                  &sigma;&tau;&iota;&sigmaf;
                  &alpha;&rho;&mu;ό&delta;&iota;&epsilon;&sigmaf;
                  &alpha;&rho;&chi;έ&sigmaf;.
                </p>
                <h4>
                  5. &Alpha;&pi;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
                  &epsilon;&upsilon;&theta;ύ&nu;&eta;&sigmaf;
                </h4>
                <p>
                  5.1
                  &Kappa;&alpha;&tau;&alpha;&beta;ά&lambda;&lambda;&epsilon;&tau;&alpha;&iota;
                  &kappa;ά&theta;&epsilon; &delta;&upsilon;&nu;&alpha;&tau;ή
                  &pi;&rho;&omicron;&sigma;&pi;ά&theta;&epsilon;&iota;&alpha;
                  &gamma;&iota;&alpha; &tau;&eta;&nu;
                  &pi;&alpha;&rho;&omicron;&chi;ή
                  &chi;&rho;ή&sigma;&iota;&mu;&omega;&nu;,
                  &alpha;&kappa;&rho;&iota;&beta;ώ&nu; &kappa;&alpha;&iota;
                  &pi;&lambda;ή&rho;&omega;&nu;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;.
                  &Omega;&sigma;&tau;ό&sigma;&omicron;, &delta;&epsilon;&nu;
                  &mu;&pi;&omicron;&rho;&omicron;ύ&mu;&epsilon; &nu;&alpha;
                  &epsilon;&gamma;&gamma;&upsilon;&eta;&theta;&omicron;ύ&mu;&epsilon;
                  ό&tau;&iota; &delta;&epsilon;&nu;
                  &upsilon;&pi;ά&rho;&chi;&omicron;&upsilon;&nu;
                  &lambda;ά&theta;&eta;. &Eta; Plumo Ltd &delta;&epsilon;&nu;
                  &pi;&rho;&omicron;&beta;ά&lambda;&lambda;&epsilon;&iota;
                  &kappa;&alpha;&mu;ί&alpha; &alpha;&xi;ί&omega;&sigma;&eta;,
                  &upsilon;&pi;ό&sigma;&chi;&epsilon;&sigma;&eta; ή
                  &epsilon;&gamma;&gamma;ύ&eta;&sigma;&eta;
                  &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon;
                  &tau;&eta;&nu;
                  &alpha;&kappa;&rho;ί&beta;&epsilon;&iota;&alpha;,
                  &tau;&eta;&nu; &pi;&lambda;&eta;&rho;ό&tau;&eta;&tau;&alpha;,
                  &tau;&eta;
                  &chi;&rho;&eta;&sigma;&iota;&mu;ό&tau;&eta;&tau;&alpha; ή
                  &tau;&eta;&nu;
                  &epsilon;&pi;ά&rho;&kappa;&epsilon;&iota;&alpha;
                  &tau;&omicron;&upsilon;
                  &pi;&epsilon;&rho;&iota;&epsilon;&chi;&omicron;&mu;έ&nu;&omicron;&upsilon;
                  &tau;&omicron;&upsilon;
                  &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;
                  &kappa;&alpha;&iota;
                  &alpha;&pi;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&alpha;&iota;
                  &rho;&eta;&tau;ά &kappa;ά&theta;&epsilon;
                  &epsilon;ί&delta;&omicron;&upsilon;&sigmaf;
                  &epsilon;&upsilon;&theta;ύ&nu;&eta;&sigmaf;
                  &gamma;&iota;&alpha; &lambda;ά&theta;&eta;
                  &kappa;&alpha;&iota;
                  &pi;&alpha;&rho;&alpha;&lambda;&epsilon;ί&psi;&epsilon;&iota;&sigmaf;
                  &sigma;&tau;&omicron;
                  &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
                  &tau;&omicron;&upsilon;.
                </p>
                <p>
                  5.2 &Omicron;ύ&tau;&epsilon; &eta; Plumo Ltd,
                  &omicron;ύ&tau;&epsilon; &omicron;&iota;
                  &upsilon;&pi;ά&lambda;&lambda;&eta;&lambda;&omicron;ί
                  &tau;&eta;&sigmaf;
                  (&Pi;&rho;&omicron;&sigma;&omega;&pi;&iota;&kappa;ό),
                  &omicron;&iota;
                  &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;ά&tau;&epsilon;&sigmaf;
                  &tau;&eta;&sigmaf;, &omicron;&iota;
                  &epsilon;&rho;&gamma;&omicron;&lambda;ά&beta;&omicron;&iota;
                  &tau;&eta;&sigmaf;,
                  &pi;&alpha;&rho;έ&chi;&omicron;&upsilon;&nu;
                  &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &epsilon;&gamma;&gamma;ύ&eta;&sigma;&eta;, &rho;&eta;&tau;ή,
                  &sigma;&iota;&omega;&pi;&eta;&rho;ή ή &epsilon;&kappa;
                  &tau;&omicron;&upsilon; &nu;ό&mu;&omicron;&upsilon; ή
                  ά&lambda;&lambda;&omicron;&upsilon;
                  &kappa;&alpha;&nu;&omicron;&nu;&iota;&sigma;&mu;&omicron;ύ
                  &epsilon;&pi;&iota;&beta;&alpha;&lambda;&lambda;ό&mu;&epsilon;&nu;&eta;,
                  &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&eta;&sigmaf;
                  &epsilon;&nu;&delta;&epsilon;&iota;&kappa;&tau;&iota;&kappa;ά,
                  &omicron;&pi;&omicron;&iota;&alpha;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &epsilon;&gamma;&gamma;ύ&eta;&sigma;&eta;&sigmaf; ό&tau;&iota;
                  &delta;&epsilon;&nu; έ&chi;&omicron;&upsilon;&nu;
                  &pi;&alpha;&rho;&alpha;&beta;&iota;&alpha;&sigma;&tau;&epsilon;ί
                  &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha;, ή
                  &tau;ί&tau;&lambda;&omicron;&iota;
                  &tau;&rho;ί&tau;&omega;&nu;, ή
                  &omicron;&pi;&omicron;&iota;&alpha;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &epsilon;&gamma;&gamma;ύ&eta;&sigma;&eta;
                  &epsilon;&mu;&pi;&omicron;&rho;&epsilon;&upsilon;&sigma;&iota;&mu;ό&tau;&eta;&tau;&alpha;&sigmaf;,
                  ή
                  &kappa;&alpha;&tau;&alpha;&lambda;&lambda;&eta;&lambda;ό&tau;&eta;&tau;&alpha;&sigmaf;
                  &gamma;&iota;&alpha;
                  &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&omicron;
                  &sigma;&kappa;&omicron;&pi;ό.
                </p>
                <p>
                  &Kappa;&alpha;&mu;ί&alpha;
                  &epsilon;&gamma;&gamma;ύ&eta;&sigma;&eta;
                  &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &epsilon;ί&delta;&omicron;&upsilon;&sigmaf;
                  &delta;&epsilon;&nu;
                  &pi;&alpha;&rho;έ&chi;&epsilon;&tau;&alpha;&iota;
                  &sigma;&epsilon; &sigma;&chi;έ&sigma;&eta; &mu;&epsilon;
                  &tau;&omicron;
                  &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
                  &tau;&omega;&nu;
                  &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ώ&nu;
                  &tau;ό&pi;&omega;&nu;/&iota;&sigma;&tau;&omicron;&tau;ό&pi;&omega;&nu;
                  &tau;&rho;ί&tau;&omega;&nu; &pi;&omicron;&upsilon;
                  &delta;&iota;&alpha;&theta;έ&tau;&omicron;&upsilon;&nu;
                  &sigma;&upsilon;&nu;&delta;έ&sigma;&mu;&omicron;&upsilon;&sigmaf;
                  &alpha;&pi;ό &tau;&omicron;&nu;
                  &pi;&alpha;&rho;ό&nu;&tau;&alpha;
                  &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό
                  &tau;ό&pi;&omicron;/&iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
                  &kappa;&alpha;&iota;
                  &alpha;&pi;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&alpha;&iota;
                  &rho;&eta;&tau;ά &kappa;ά&theta;&epsilon;
                  &epsilon;&upsilon;&theta;ύ&nu;&eta; &kappa;ά&theta;&epsilon;
                  &epsilon;ί&delta;&omicron;&upsilon;&sigmaf;.
                </p>
                <p>
                  5.3. &Eta; Plumo Ltd &delta;&epsilon;&nu;
                  &epsilon;&upsilon;&theta;ύ&nu;&epsilon;&tau;&alpha;&iota;
                  &sigma;&epsilon; &kappa;&alpha;&mu;ί&alpha;
                  &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
                  &gamma;&iota;&alpha;
                  &epsilon;&iota;&delta;&iota;&kappa;έ&sigmaf;,
                  έ&mu;&mu;&epsilon;&sigma;&epsilon;&sigmaf; ή
                  &epsilon;&pi;&alpha;&kappa;ό&lambda;&omicron;&upsilon;&theta;&epsilon;&sigmaf;
                  &zeta;&eta;&mu;ί&epsilon;&sigmaf; ή
                  &omicron;&pi;&omicron;&iota;&epsilon;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &zeta;&eta;&mu;ί&epsilon;&sigmaf; &pi;&omicron;&upsilon;
                  &pi;&rho;&omicron;&kappa;ύ&pi;&tau;&omicron;&upsilon;&nu;
                  &alpha;&pi;ό &alpha;&pi;ώ&lambda;&epsilon;&iota;&alpha;
                  &chi;&rho;ή&sigma;&eta;&sigmaf;,
                  &alpha;&pi;ώ&lambda;&epsilon;&iota;&alpha;
                  &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu; ή
                  &alpha;&pi;ώ&lambda;&epsilon;&iota;&alpha;
                  &epsilon;&sigma;ό&delta;&omega;&nu; ή
                  &kappa;&epsilon;&rho;&delta;ώ&nu;, &epsilon;ί&tau;&epsilon;
                  &pi;&rho;ό&kappa;&epsilon;&iota;&tau;&alpha;&iota;
                  &gamma;&iota;&alpha; &zeta;&eta;&mu;ί&epsilon;&sigmaf;
                  &alpha;&pi;ό &alpha;&gamma;&omega;&gamma;ή
                  &lambda;ό&gamma;&omega;
                  &sigma;ύ&mu;&beta;&alpha;&sigma;&eta;&sigmaf;,
                  &alpha;&mu;έ&lambda;&epsilon;&iota;&alpha;&sigmaf; ή
                  ά&lambda;&lambda;&eta;&sigmaf;
                  &alpha;&iota;&tau;ί&alpha;&sigmaf;, &pi;&omicron;&upsilon;
                  &pi;&rho;&omicron;&kappa;ύ&pi;&tau;&epsilon;&iota;
                  &alpha;&pi;ό ή &sigma;&epsilon; &sigma;&chi;έ&sigma;&eta;
                  &mu;&epsilon;
                  &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;&iota;ώ&nu;
                  ή &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
                  &pi;&omicron;&upsilon;
                  &delta;&iota;&alpha;&tau;ί&theta;&epsilon;&nu;&tau;&alpha;&iota;
                  &sigma;&tau;&omicron;&nu; &pi;&alpha;&rho;ό&nu;&tau;&alpha;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;.
                </p>
                <h4>
                  6. &Alpha;&pi;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
                  &epsilon;&upsilon;&theta;ύ&nu;&eta;&sigmaf;
                  έ&gamma;&kappa;&rho;&iota;&sigma;&eta;&sigmaf;
                </h4>
                <p>
                  6.1. &Omicron; &pi;&alpha;&rho;ώ&nu;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&sigmaf;
                  &kappa;&alpha;&iota; &tau;&alpha;
                  έ&gamma;&gamma;&rho;&alpha;&phi;&alpha; &pi;&omicron;&upsilon;
                  &alpha;&nu;&alpha;&rho;&tau;ώ&nu;&tau;&alpha;&iota;
                  &sigma;&epsilon; &alpha;&upsilon;&tau;ό&nu;
                  &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota;
                  &nu;&alpha;
                  &pi;&epsilon;&rho;&iota;έ&chi;&omicron;&upsilon;&nu;
                  &upsilon;&pi;&epsilon;&rho;&kappa;&epsilon;ί&mu;&epsilon;&nu;&omicron;&upsilon;&sigmaf;
                  &sigma;&upsilon;&nu;&delta;έ&sigma;&mu;&omicron;&upsilon;&sigmaf;
                  ή &delta;&epsilon;ί&kappa;&tau;&epsilon;&sigmaf;
                  &pi;&rho;&omicron;&sigmaf;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
                  &pi;&omicron;&upsilon;
                  &delta;&eta;&mu;&iota;&omicron;&upsilon;&rho;&gamma;&omicron;ύ&nu;&tau;&alpha;&iota;
                  &kappa;&alpha;&iota;
                  &delta;&iota;&alpha;&tau;&eta;&rho;&omicron;ύ&nu;&tau;&alpha;&iota;
                  &alpha;&pi;ό ά&lambda;&lambda;&omicron;&upsilon;&sigmaf;
                  &delta;&eta;&mu;ό&sigma;&iota;&omicron;&upsilon;&sigmaf;
                  &kappa;&alpha;&iota;
                  &iota;&delta;&iota;&omega;&tau;&iota;&kappa;&omicron;ύ&sigmaf;
                  &omicron;&rho;&gamma;&alpha;&nu;&iota;&sigma;&mu;&omicron;ύ&sigmaf;.
                  &Eta; Plumo Ltd &delta;&epsilon;&nu;
                  &epsilon;&gamma;&gamma;&upsilon;ά&tau;&alpha;&iota;
                  &tau;&eta;&nu;
                  &alpha;&kappa;&rho;ί&beta;&epsilon;&iota;&alpha;, &tau;&eta;
                  &sigma;&upsilon;&nu;ά&phi;&epsilon;&iota;&alpha;, &tau;&eta;
                  &chi;&rho;&eta;&sigma;&iota;&mu;ό&tau;&eta;&tau;&alpha;,
                  &tau;&eta;&nu;
                  &epsilon;&pi;&iota;&kappa;&alpha;&iota;&rho;ό&tau;&eta;&tau;&alpha;
                  ή &tau;&eta;&nu; &pi;&lambda;&eta;&rho;ό&tau;&eta;&tau;&alpha;
                  &omicron;&pi;&omicron;&iota;&alpha;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &sigma;&upsilon;&nu;&delta;&epsilon;&delta;&epsilon;&mu;έ&nu;&eta;&sigmaf;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&alpha;&sigmaf;.
                </p>
                <p>
                  6.2. &Eta;
                  &sigma;&upsilon;&mu;&pi;&epsilon;&rho;ί&lambda;&eta;&psi;&eta;
                  &sigma;&upsilon;&nu;&delta;έ&sigma;&mu;&omega;&nu; ή
                  &delta;&epsilon;&iota;&kappa;&tau;ώ&nu;
                  &pi;&rho;&omicron;&sigmaf;
                  ά&lambda;&lambda;&omicron;&upsilon;&sigmaf;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;&sigmaf;
                  &delta;&epsilon;&nu;
                  &alpha;&pi;&omicron;&sigma;&kappa;&omicron;&pi;&epsilon;ί
                  &sigma;&tau;&eta;&nu; &alpha;&pi;ό&delta;&omicron;&sigma;&eta;
                  &sigma;&pi;&omicron;&upsilon;&delta;&alpha;&iota;ό&tau;&eta;&tau;&alpha;&sigmaf;
                  &sigma;&tau;&omicron;&upsilon;&sigmaf; &epsilon;&nu;
                  &lambda;ό&gamma;&omega;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;&sigmaf;
                  &kappa;&alpha;&iota; &sigma;&tau;&iota;&sigmaf;
                  &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
                  &pi;&omicron;&upsilon;
                  &pi;&epsilon;&rho;&iota;έ&chi;&omicron;&nu;&tau;&alpha;&iota;
                  &sigma;&epsilon; &alpha;&upsilon;&tau;&omicron;ύ&sigmaf;,
                  &omicron;ύ&tau;&epsilon;
                  &alpha;&pi;&omicron;&sigma;&kappa;&omicron;&pi;&epsilon;ί
                  &sigma;&tau;&eta;&nu;
                  &upsilon;&pi;&omicron;&sigma;&tau;ή&rho;&iota;&xi;&eta;,
                  &sigma;ύ&sigma;&tau;&alpha;&sigma;&eta; ή
                  &epsilon;&kappa;&delta;ή&lambda;&omega;&sigma;&eta;
                  &epsilon;ύ&nu;&omicron;&iota;&alpha;&sigmaf;
                  &gamma;&iota;&alpha; &tau;&iota;&sigmaf;
                  &alpha;&pi;ό&psi;&epsilon;&iota;&sigmaf;
                  &pi;&omicron;&upsilon;
                  &epsilon;&kappa;&phi;&rho;ά&zeta;&omicron;&nu;&tau;&alpha;&iota;,
                  &tau;&alpha; &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ά
                  &pi;&rho;&omicron;ϊό&nu;&tau;&alpha;, ή &tau;&iota;&sigmaf;
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
                  &pi;&omicron;&upsilon;
                  &pi;&rho;&omicron;&sigma;&phi;έ&rho;&omicron;&nu;&tau;&alpha;&iota;
                  &sigma;&tau;&omicron;&upsilon;&sigmaf; &epsilon;&nu;
                  &lambda;ό&gamma;&omega;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;&sigmaf;
                  ή &gamma;&iota;&alpha; &tau;&omicron;&upsilon;&sigmaf;
                  &omicron;&rho;&gamma;&alpha;&nu;&iota;&sigma;&mu;&omicron;ύ&sigmaf;
                  &pi;&omicron;&upsilon;
                  &chi;&rho;&eta;&mu;&alpha;&tau;&omicron;&delta;&omicron;&tau;&omicron;ύ&nu;
                  &tau;&omicron;&upsilon;&sigmaf;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;&sigmaf;,
                  &mu;&epsilon; &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ή
                  &epsilon;&pi;&omega;&nu;&upsilon;&mu;ί&alpha;,
                  &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ό
                  &sigma;ή&mu;&alpha;,
                  &kappa;&alpha;&tau;&alpha;&sigma;&kappa;&epsilon;&upsilon;ή ή
                  &mu;&epsilon; ά&lambda;&lambda;&omicron;
                  &tau;&rho;ό&pi;&omicron;.
                </p>
                <p>
                  6.3. &Eta; &alpha;&nu;&alpha;&phi;&omicron;&rho;ά
                  &sigma;&tau;&omicron;&nu; &pi;&alpha;&rho;ό&nu;&tau;&alpha;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
                  &sigma;&epsilon;
                  &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&alpha;
                  &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ά
                  &pi;&rho;&omicron;ϊό&nu;&tau;&alpha;,
                  &delta;&iota;&alpha;&delta;&iota;&kappa;&alpha;&sigma;ί&epsilon;&sigmaf;
                  ή &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf; ή
                  &eta; &chi;&rho;ή&sigma;&eta;
                  &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ή&sigmaf;,
                  &epsilon;&tau;&alpha;&iota;&rho;&iota;&kappa;ή&sigmaf; ή
                  ά&lambda;&lambda;&eta;&sigmaf;
                  &epsilon;&pi;&omega;&nu;&upsilon;&mu;ί&alpha;&sigmaf;
                  &gamma;ί&nu;&epsilon;&tau;&alpha;&iota;
                  &pi;&rho;&omicron;&sigmaf;
                  &epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;
                  &kappa;&alpha;&iota;
                  &delta;&iota;&epsilon;&upsilon;&kappa;ό&lambda;&upsilon;&nu;&sigma;&eta;
                  &tau;&omega;&nu;
                  &epsilon;&pi;&iota;&sigma;&kappa;&epsilon;&pi;&tau;ώ&nu;
                  &tau;&omicron;&upsilon;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;
                  &kappa;&alpha;&iota; &delta;&epsilon;&nu;
                  &sigma;&upsilon;&nu;&iota;&sigma;&tau;ά
                  έ&gamma;&kappa;&rho;&iota;&sigma;&eta;,
                  &sigma;ύ&sigma;&tau;&alpha;&sigma;&eta; ή
                  &pi;&alpha;&rho;&omicron;&chi;ή
                  &epsilon;ύ&nu;&omicron;&iota;&alpha;&sigmaf; &alpha;&pi;ό
                  &tau;&eta;&nu; Plumo Ltd.
                </p>
                <p>
                  6.4. &Omicron;&iota; &alpha;&pi;ό&psi;&epsilon;&iota;&sigmaf;
                  &pi;&omicron;&upsilon;
                  &epsilon;&kappa;&phi;&rho;ά&zeta;&omicron;&nu;&tau;&alpha;&iota;
                  &alpha;&pi;ό
                  &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
                  ά&tau;&omicron;&mu;&omicron; &sigma;&tau;&omicron;
                  &delta;&iota;&alpha;&delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;ό
                  &phi;ό&rho;&omicron;&upsilon;&mu;
                  &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&omicron;ύ&nu;
                  &tau;&iota;&sigmaf; &alpha;&pi;ό&psi;&epsilon;&iota;&sigmaf;
                  &tau;&omicron;&upsilon;
                  &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&omicron;&upsilon;
                  &alpha;&tau;ό&mu;&omicron;&upsilon; &kappa;&alpha;&iota;
                  &delta;&epsilon;&nu;
                  &alpha;&nu;&tau;&iota;&kappa;&alpha;&tau;&omicron;&pi;&tau;&rho;ί&zeta;&omicron;&upsilon;&nu;
                  ή
                  &alpha;&nu;&tau;&iota;&pi;&rho;&omicron;&sigma;&omega;&pi;&epsilon;ύ&omicron;&upsilon;&nu;
                  &sigma;&epsilon; &kappa;&alpha;&mu;ί&alpha;
                  &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
                  &tau;&iota;&sigmaf; &alpha;&pi;ό&psi;&epsilon;&iota;&sigmaf;
                  &tau;&eta;&sigmaf; Plumo Ltd.
                </p>
                <h4>
                  7. &Pi;&nu;&epsilon;&upsilon;&mu;&alpha;&tau;&iota;&kappa;ά
                  &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&alpha;{" "}
                </h4>
                <p>
                  7.1. Ό&lambda;&omicron; &tau;&omicron;
                  &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
                  &tau;&omicron;&upsilon;
                  &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf;
                  &delta;&iota;&kappa;&tau;&upsilon;&alpha;&kappa;&omicron;ύ
                  &tau;ό&pi;&omicron;&upsilon;, &kappa;&alpha;&theta;ώ&sigmaf;
                  &kappa;&alpha;&iota; ό&lambda;&omicron; &tau;&omicron;
                  &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
                  &omicron;&pi;&omicron;&iota;&omega;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &epsilon;&gamma;&gamma;&rho;ά&phi;&omega;&nu;
                  &pi;&omicron;&upsilon;
                  &pi;&alpha;&rho;έ&chi;&omicron;&nu;&tau;&alpha;&iota;
                  &sigma;&tau;&omicron;&upsilon;&sigmaf;
                  &epsilon;&pi;&iota;&sigma;&kappa;έ&pi;&tau;&epsilon;&sigmaf; ή
                  &tau;&omicron;&upsilon;&sigmaf;
                  &pi;&epsilon;&lambda;ά&tau;&epsilon;&sigmaf; (&pi;.&chi;.
                  &sigma;&epsilon;
                  &epsilon;&nu;&eta;&mu;&epsilon;&rho;&omega;&tau;&iota;&kappa;ά
                  &delta;&epsilon;&lambda;&tau;ί&alpha;)
                  &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&epsilon;ί
                  &iota;&delta;&iota;&omicron;&kappa;&tau;&eta;&sigma;ί&alpha;
                  &tau;&eta;&sigmaf; Plumo Ltd, &epsilon;&kappa;&tau;ό&sigmaf;
                  &epsilon;ά&nu;
                  &alpha;&nu;&alpha;&phi;έ&rho;&epsilon;&tau;&alpha;&iota;
                  &delta;&iota;&alpha;&phi;&omicron;&rho;&epsilon;&tau;&iota;&kappa;ά.
                </p>
                <p>
                  7.2. &Kappa;&alpha;&nu;έ&nu;&alpha;&sigmaf;
                  &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &delta;&epsilon;&nu;
                  &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
                  &alpha;&nu;&tau;&iota;&gamma;&rho;ά&psi;&epsilon;&iota;,
                  &nu;&alpha;
                  &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;,
                  &nu;&alpha;
                  &delta;&eta;&mu;&omicron;&sigma;&iota;&epsilon;ύ&sigma;&epsilon;&iota;,
                  &nu;&alpha;
                  &mu;&epsilon;&tau;&alpha;&delta;ώ&sigma;&epsilon;&iota;,
                  &nu;&alpha;
                  &mu;&epsilon;&tau;&alpha;&beta;&iota;&beta;ά&sigma;&epsilon;&iota;
                  ή &nu;&alpha; &pi;&omega;&lambda;ή&sigma;&epsilon;&iota;,
                  &nu;&alpha;
                  &alpha;&nu;&alpha;&pi;&alpha;&rho;&alpha;&gamma;ά&gamma;&epsilon;&iota;,
                  &nu;&alpha;
                  &delta;&eta;&mu;&iota;&omicron;&upsilon;&rho;&gamma;ή&sigma;&epsilon;&iota;
                  &pi;&alpha;&rho;ά&gamma;&omega;&gamma;&alpha;
                  έ&rho;&gamma;&alpha;, &nu;&alpha;
                  &delta;&iota;&alpha;&nu;&epsilon;ί&mu;&epsilon;&iota;,
                  &nu;&alpha; &pi;&rho;&omicron;&beta;ά&lambda;&epsilon;&iota; ή
                  &nu;&alpha;
                  &epsilon;&kappa;&mu;&epsilon;&tau;&alpha;&lambda;&lambda;&epsilon;&upsilon;&tau;&epsilon;ί
                  &mu;&epsilon;
                  &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &tau;&rho;ό&pi;&omicron;
                  &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;
                  &tau;&omicron;&upsilon;
                  &iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;,
                  &epsilon;&nu; ό&lambda;&omega; ή &epsilon;&nu;
                  &mu;έ&rho;&epsilon;&iota;, &pi;&alpha;&rho;ά
                  &mu;ό&nu;&omicron; &mu;&epsilon; &tau;&eta; &rho;&eta;&tau;ή
                  &gamma;&rho;&alpha;&pi;&tau;ή
                  &sigma;&upsilon;&gamma;&kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta;
                  &tau;&eta;&sigmaf; Plumo Ltd.
                </p>
                <h4>
                  8. &Delta;&omega;&rho;&epsilon;ά&nu;
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;{" "}
                </h4>
                <p>
                  8.1. &Eta; Plumo Ltd &pi;&alpha;&rho;έ&chi;&epsilon;&iota;
                  &mu;&iota;&alpha; &sigma;&epsilon;&iota;&rho;ά
                  &delta;&omega;&rho;&epsilon;ά&nu;
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;&iota;ώ&nu;
                  &mu;έ&sigma;&omega; &tau;&omicron;&upsilon;
                  &iota;&sigma;&tau;&omicron;&tau;ό&pi;&omicron;&upsilon;
                  &tau;&eta;&sigmaf;, ό&pi;&omega;&sigmaf;
                  &epsilon;&beta;&delta;&omicron;&mu;&alpha;&delta;&iota;&alpha;ί&alpha;
                  &epsilon;&nu;&eta;&mu;&epsilon;&rho;&omega;&tau;&iota;&kappa;ά
                  &delta;&epsilon;&lambda;&tau;ί&alpha;,
                  &lambda;&epsilon;&upsilon;&kappa;ά
                  έ&gamma;&gamma;&rho;&alpha;&phi;&alpha; &kappa;.&lambda;&pi;.
                  &Delta;&epsilon;&nu; &upsilon;&pi;ά&rho;&chi;&epsilon;&iota;
                  &sigma;ύ&mu;&beta;&alpha;&sigma;&eta; &mu;&epsilon;
                  &tau;&eta;&nu; Plumo Ltd &gamma;&iota;&alpha;
                  &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &delta;&omega;&rho;&epsilon;ά&nu;
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;,
                  &epsilon;&pi;&omicron;&mu;έ&nu;&omega;&sigmaf;
                  &kappa;&alpha;&nu;έ&nu;&alpha;&sigmaf;
                  &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &delta;&epsilon;&nu;
                  &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
                  &gamma;ί&nu;&epsilon;&iota;
                  &pi;&epsilon;&lambda;ά&tau;&eta;&sigmaf;
                  &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ώ&nu;&tau;&alpha;&sigmaf;
                  &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &delta;&omega;&rho;&epsilon;ά&nu;
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;
                  &kappa;&alpha;&iota; &eta; Plumo Ltd &delta;&epsilon;&nu;
                  &epsilon;&upsilon;&theta;ύ&nu;&epsilon;&tau;&alpha;&iota;
                  έ&nu;&alpha;&nu;&tau;&iota;
                  &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &chi;&rho;ή&sigma;&tau;&eta; &mu;&epsilon;
                  &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &tau;&rho;ό&pi;&omicron; &gamma;&iota;&alpha;
                  &zeta;&eta;&tau;ή&mu;&alpha;&tau;&alpha;
                  &pi;&omicron;&upsilon; &mu;&pi;&omicron;&rho;&epsilon;ί
                  &nu;&alpha;
                  &pi;&rho;&omicron;&kappa;ύ&pi;&tau;&omicron;&upsilon;&nu;
                  &alpha;&pi;ό &tau;&eta; &chi;&rho;ή&sigma;&eta;
                  &omicron;&pi;&omicron;&iota;&alpha;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &delta;&omega;&rho;&epsilon;ά&nu;
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;.
                </p>
                <h4>
                  9. &Pi;&rho;&omicron;ϊό&nu;&tau;&alpha; &kappa;&alpha;&iota;
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
                  &pi;&omicron;&upsilon;
                  &alpha;&gamma;&omicron;&rho;ά&zeta;&omicron;&nu;&tau;&alpha;&iota;
                  &alpha;&pi;ό &tau;&eta;&nu; Plumo Ltd{" "}
                </h4>
                <p>
                  9.1. &Eta; Plumo Ltd &delta;&epsilon;&nu;
                  &pi;&rho;&omicron;&beta;&alpha;ί&nu;&epsilon;&iota;
                  &sigma;&epsilon; &kappa;&alpha;&mu;ί&alpha;
                  &epsilon;&kappa;&pi;&rho;&omicron;&sigma;ώ&pi;&eta;&sigma;&eta;
                  &kappa;&alpha;&iota; &delta;&epsilon;&nu;
                  &pi;&alpha;&rho;έ&chi;&epsilon;&iota;
                  &kappa;&alpha;&mu;ί&alpha;
                  &epsilon;&gamma;&gamma;ύ&eta;&sigma;&eta; &sigma;&epsilon;
                  &sigma;&chi;έ&sigma;&eta; &mu;&epsilon;
                  &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &alpha;&pi;ό &tau;&alpha; &pi;&rho;&omicron;ϊό&nu;&tau;&alpha;
                  ή &tau;&iota;&sigmaf;
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&epsilon;&sigmaf;
                  &pi;&omicron;&upsilon;
                  &pi;&alpha;&rho;έ&chi;&omicron;&nu;&tau;&alpha;&iota;
                  &alpha;&pi;ό &tau;&eta;&nu; Plumo Ltd. &Delta;&epsilon;&nu;
                  &upsilon;&pi;&omicron;&delta;&eta;&lambda;ώ&nu;&epsilon;&iota;
                  ό&tau;&iota;
                  &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &pi;&rho;&omicron;ϊό&nu; ή
                  &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;
                  &epsilon;ί&nu;&alpha;&iota;
                  &kappa;&alpha;&tau;ά&lambda;&lambda;&eta;&lambda;&omicron;
                  &gamma;&iota;&alpha;
                  &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&eta;
                  &chi;&rho;ή&sigma;&eta;.
                </p>
                <p>
                  9.2 &Tau;&alpha; έ&gamma;&gamma;&rho;&alpha;&phi;&alpha;
                  &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota;
                  &nu;&alpha;
                  &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&nu;
                  &tau;&epsilon;&chi;&nu;&iota;&kappa;έ&sigmaf;
                  &alpha;&nu;&alpha;&kappa;&rho;ί&beta;&epsilon;&iota;&epsilon;&sigmaf;
                  ή
                  &tau;&upsilon;&pi;&omicron;&gamma;&rho;&alpha;&phi;&iota;&kappa;ά
                  &lambda;ά&theta;&eta;.
                </p>
                <h4>
                  10. &Iota;&sigma;&chi;ύ&sigmaf;
                  &Delta;&iota;&alpha;&tau;ά&xi;&epsilon;&omega;&nu;
                </h4>
                <p>
                  10.1. &Epsilon;ά&nu;
                  &omicron;&pi;&omicron;&tau;&epsilon;&delta;ή&pi;&omicron;&tau;&epsilon;,
                  &omicron;&pi;&omicron;&iota;&omicron;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &alpha;&pi;ό &tau;&omicron;&upsilon;&sigmaf;
                  &pi;&alpha;&rho;ό&nu;&tau;&epsilon;&sigmaf;
                  ό&rho;&omicron;&upsilon;&sigmaf;
                  &kappa;&rho;&iota;&theta;&epsilon;ί &sigma;&epsilon;
                  &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &delta;&iota;&kappa;&alpha;&iota;&omicron;&delta;&omicron;&sigma;ί&alpha;
                  ά&kappa;&upsilon;&rho;&omicron;&sigmaf;,
                  &alpha;&kappa;&upsilon;&rho;ώ&sigma;&iota;&mu;&omicron;&sigmaf;
                  ή &mu;&eta;
                  &epsilon;&kappa;&tau;&epsilon;&lambda;&epsilon;&sigma;&tau;ό&sigmaf;,
                  &tau;ό&tau;&epsilon; &theta;&alpha;
                  &pi;&rho;έ&pi;&epsilon;&iota; &nu;&alpha;
                  &theta;&epsilon;&omega;&rho;&eta;&theta;&epsilon;ί
                  ό&tau;&iota; έ&chi;&epsilon;&iota;
                  &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;&iota;&eta;&theta;&epsilon;ί
                  ή
                  &pi;&epsilon;&rho;&iota;&omicron;&rho;&iota;&sigma;&theta;&epsilon;ί,
                  &mu;ό&nu;&omicron; &sigma;&tau;&omicron;&nu;
                  &epsilon;&lambda;ά&chi;&iota;&sigma;&tau;&omicron;
                  &alpha;&nu;&alpha;&gamma;&kappa;&alpha;ί&omicron;
                  &beta;&alpha;&theta;&mu;ό, ώ&sigma;&tau;&epsilon; &nu;&alpha;
                  &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
                  &upsilon;&pi;&alpha;&chi;&theta;&epsilon;ί
                  &sigma;&tau;&omicron;&upsilon;&sigmaf;
                  &nu;ό&mu;&omicron;&upsilon;&sigmaf; &tau;&eta;&sigmaf;
                  &epsilon;&nu; &lambda;ό&gamma;&omega;
                  &delta;&iota;&kappa;&alpha;&iota;&omicron;&delta;&omicron;&sigma;ί&alpha;&sigmaf;
                  &kappa;&alpha;&iota; &nu;&alpha;
                  &alpha;&pi;&omicron;&tau;&rho;&alpha;&pi;&epsilon;ί &eta;
                  &alpha;&kappa;&upsilon;&rho;ό&tau;&eta;&tau;ά
                  &tau;&omicron;&upsilon;, &kappa;&alpha;&iota; &theta;&alpha;
                  &epsilon;ί&nu;&alpha;&iota;
                  &delta;&epsilon;&sigma;&mu;&epsilon;&upsilon;&tau;&iota;&kappa;ό&sigmaf;
                  &sigma;&epsilon; &alpha;&upsilon;&tau;ή &tau;&eta;&nu;
                  &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;&iota;&eta;&mu;έ&nu;&eta;
                  ή &mu;&epsilon;&iota;&omega;&mu;έ&nu;&eta;
                  &mu;&omicron;&rho;&phi;ή. &Mu;&epsilon; &tau;&eta;&nu;
                  &epsilon;&pi;&iota;&phi;ύ&lambda;&alpha;&xi;&eta;
                  &alpha;&upsilon;&tau;ή, &kappa;ά&theta;&epsilon;
                  &delta;&iota;ά&tau;&alpha;&xi;&eta;
                  &epsilon;&rho;&mu;&eta;&nu;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
                  &alpha;&upsilon;&tau;&omicron;&tau;&epsilon;&lambda;ώ&sigmaf;
                  &kappa;&alpha;&iota;
                  &alpha;&nu;&epsilon;&xi;ά&rho;&tau;&eta;&tau;&alpha;
                  &kappa;&alpha;&iota; &delta;&epsilon;&nu;
                  &epsilon;&pi;&eta;&rho;&epsilon;ά&zeta;&epsilon;&iota;
                  &mu;&epsilon; &kappa;&alpha;&nu;έ&nu;&alpha;
                  &tau;&rho;ό&pi;&omicron;
                  &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
                  ά&lambda;&lambda;&omicron; &alpha;&pi;ό
                  &tau;&omicron;&upsilon;&sigmaf;
                  &pi;&alpha;&rho;ό&nu;&tau;&epsilon;&sigmaf;
                  ό&rho;&omicron;&upsilon;&sigmaf;.
                </p>
                <h4>
                  11. &Kappa;&alpha;&mu;ί&alpha;
                  &pi;&alpha;&rho;&alpha;ί&tau;&eta;&sigma;&eta;{" "}
                </h4>
                <p>
                  11.1. &Kappa;&alpha;&mu;ί&alpha;
                  &pi;&alpha;&rho;&alpha;ί&tau;&eta;&sigma;&eta; &alpha;&pi;ό
                  &tau;&eta;&nu; Plumo Ltd, &kappa;&alpha;&tau;ά &tau;&eta;&nu;
                  ά&sigma;&kappa;&eta;&sigma;&eta;
                  &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&omicron;&sigmaf;,
                  &epsilon;&xi;&omicron;&upsilon;&sigma;ί&alpha;&sigmaf; ή
                  &delta;&iota;ά&tau;&alpha;&xi;&eta;&sigmaf;
                  &delta;&upsilon;&nu;ά&mu;&epsilon;&iota;
                  &tau;&omicron;&upsilon;
                  &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf;
                  &delta;&epsilon;&nu; &theta;&alpha;
                  &lambda;&epsilon;&iota;&tau;&omicron;&upsilon;&rho;&gamma;&epsilon;ί
                  &omega;&sigmaf; &pi;&alpha;&rho;&alpha;ί&tau;&eta;&sigma;&eta;
                  &alpha;&pi;ό
                  &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
                  ά&lambda;&lambda;&omicron;
                  &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha; ή &alpha;&pi;ό
                  &tau;&omicron; ί&delta;&iota;&omicron;
                  &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
                  &sigma;&epsilon;
                  &mu;&epsilon;&lambda;&lambda;&omicron;&nu;&tau;&iota;&kappa;ό
                  &chi;&rho;ό&nu;&omicron; - &omicron;ύ&tau;&epsilon;
                  &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &kappa;&alpha;&theta;&upsilon;&sigma;&tau;έ&rho;&eta;&sigma;&eta;
                  &sigma;&tau;&eta;&nu; ά&sigma;&kappa;&eta;&sigma;&eta;
                  &omicron;&pi;&omicron;&iota;&alpha;&sigma;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &epsilon;&xi;&omicron;&upsilon;&sigma;ί&alpha;&sigmaf; ή
                  &delta;&iota;&kappa;&alpha;&iota;ώ&mu;&alpha;&tau;&omicron;&sigmaf;
                  &theta;&alpha;
                  &epsilon;&rho;&mu;&eta;&nu;&epsilon;ύ&epsilon;&tau;&alpha;&iota;
                  &omega;&sigmaf;
                  &pi;&alpha;&rho;&alpha;ί&tau;&eta;&sigma;&eta;.
                </p>
                <h4>
                  12.
                  &Epsilon;&phi;&alpha;&rho;&mu;&omicron;&sigma;&tau;έ&omicron;
                  &delta;ί&kappa;&alpha;&iota;&omicron;{" "}
                </h4>
                <p>
                  12.1. &Omicron;&iota;
                  &pi;&alpha;&rho;ό&nu;&tau;&epsilon;&sigmaf;
                  ό&rho;&omicron;&iota;
                  &delta;&iota;έ&pi;&omicron;&nu;&tau;&alpha;&iota;
                  &kappa;&alpha;&iota;
                  &epsilon;&rho;&mu;&eta;&nu;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
                  &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon;
                  &tau;&omicron;&upsilon;&sigmaf;
                  &nu;ό&mu;&omicron;&upsilon;&sigmaf; &tau;&eta;&sigmaf;
                  &Epsilon;&Epsilon; &kappa;&alpha;&iota; &tau;&eta;&sigmaf;
                  &Alpha;&gamma;&gamma;&lambda;ί&alpha;&sigmaf;
                  &kappa;&alpha;&iota; &omicron;
                  &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
                  &alpha;&pi;&omicron;&delta;έ&chi;&epsilon;&tau;&alpha;&iota;
                  &rho;&eta;&tau;ά ό&tau;&iota; &mu;ό&nu;&omicron; &tau;&alpha;
                  &delta;&iota;&kappa;&alpha;&sigma;&tau;ή&rho;&iota;&alpha;
                  &tau;&eta;&sigmaf; &Epsilon;&Epsilon; &kappa;&alpha;&iota;
                  &tau;&eta;&sigmaf;
                  &Alpha;&gamma;&gamma;&lambda;ί&alpha;&sigmaf;
                  έ&chi;&omicron;&upsilon;&nu;
                  &delta;&iota;&kappa;&alpha;&iota;&omicron;&delta;&omicron;&sigma;ί&alpha;
                  &nu;&alpha;
                  &epsilon;&pi;&iota;&lambda;&eta;&phi;&theta;&omicron;ύ&nu;
                  &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &theta;έ&mu;&alpha;&tau;&omicron;&sigmaf;
                  &pi;&omicron;&upsilon;
                  &pi;&rho;&omicron;&kappa;ύ&pi;&tau;&epsilon;&iota; ή
                  &mu;&epsilon;
                  &omicron;&pi;&omicron;&iota;&omicron;&nu;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &tau;&rho;ό&pi;&omicron;
                  &alpha;&nu;&alpha;&kappa;ύ&pi;&tau;&epsilon;&iota;,
                  &epsilon;ί&tau;&epsilon; ά&mu;&epsilon;&sigma;&alpha;
                  &epsilon;ί&tau;&epsilon; έ&mu;&mu;&epsilon;&sigma;&alpha;
                  &kappa;&alpha;&iota;
                  &sigma;&chi;&epsilon;&tau;ί&zeta;&epsilon;&tau;&alpha;&iota;
                  &mu;&epsilon; &tau;&eta; &chi;&rho;ή&sigma;&eta;
                  &tau;&omicron;&upsilon;
                  &pi;&alpha;&rho;ό&nu;&tau;&omicron;&sigmaf;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;&upsilon;.
                  &Kappa;&alpha;&tau;ά
                  &sigma;&upsilon;&nu;έ&pi;&epsilon;&iota;&alpha;, &omicron;
                  &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
                  &pi;&alpha;&rho;&alpha;&iota;&tau;&epsilon;ί&tau;&alpha;&iota;
                  &rho;&eta;&tau;ά &alpha;&pi;ό ό&lambda;&alpha;
                  &kappa;&alpha;&iota; &alpha;&pi;ό &kappa;ά&theta;&epsilon;
                  &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha; &nu;&alpha;
                  &alpha;&sigma;&kappa;ή&sigma;&epsilon;&iota;
                  &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &alpha;&gamma;&omega;&gamma;ή
                  &omicron;&pi;&omicron;&iota;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &epsilon;ί&delta;&omicron;&upsilon;&sigmaf; &sigma;&epsilon;
                  &sigma;&chi;έ&sigma;&eta; &mu;&epsilon; &tau;&omicron;&nu;
                  &pi;&alpha;&rho;ό&nu;&tau;&alpha;
                  &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
                  &sigma;&epsilon;
                  &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &delta;&iota;&kappa;&alpha;&sigma;&tau;ή&rho;&iota;&omicron;
                  &omicron;&pi;&omicron;&upsilon;&delta;ή&pi;&omicron;&tau;&epsilon;
                  &alpha;&lambda;&lambda;&omicron;ύ &sigma;&tau;&omicron;&nu;
                  &kappa;ό&sigma;&mu;&omicron;.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PopUpTerm;
