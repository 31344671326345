import React, { useState } from "react";
import { Link } from "gatsby";
import { isLangEn } from "../helpers/localization";
import scrollTo from "gatsby-plugin-smoothscroll";

import styles from "./NavBar.module.scss";

const Navbar = () => {
  const [burgerState, setBurgerState] = useState(false);

  const menu = [
    {
      en: "Home",
      el: "Αρχική",
      link: "home",
    },
    {
      en: "About us",
      el: "Για εμάς",
      link: "about",
    },
    {
      en: "Platform",
      el: "Πλατφόρμα",
      link: "platform",
    },
    {
      en: "Products",
      el: "Προϊόντα",
      link: "products",
    },
    {
      en: "Partners",
      el: "Συνεργασίες",
      link: "partners",
    },
    {
      en: "News",
      el: "Νέα",
      link: "news",
    },
    {
      en: "Responsible Gambling",
      el: "Υπεύθυνο παιχνίδι",
      link: "responsibility",
    },
    {
      en: "Contact",
      el: "Επικοινωνία",
      link: "contact",
    },
  ];

  const filtredMenu = menu.map((obj) => {
    if (isLangEn()) {
      return { item: obj.en, link: obj.link };
    }
    return { item: obj.el, link: obj.link };
  });

  const getDisplayMenu = () => {
    return filtredMenu.map((elem) => (
      <li className={styles.link} key={elem.item}>
        <span aria-hidden="true" onClick={() => scrollTo(`#${elem.link}`)}>
          {elem.item}
        </span>
      </li>
    ));
  };

  return (
    <nav
      role="navigation"
      aria-label="main-navigation"
      className={styles.navBar}
    >
      <div className={styles.wrapper}>
        <div
          className={styles.burger}
          aria-hidden="true"
          onClick={() => {
            setBurgerState(true);
          }}
        >
          <span></span>
        </div>
        {burgerState && (
          <>
            <div
              className={styles.burger}
              aria-hidden="true"
              onClick={() => {
                setBurgerState(false);
              }}
            >
              <span className={styles.active}></span>
            </div>
            <div className={styles.menuBurger}>
              <ul>{getDisplayMenu()}</ul>
            </div>
          </>
        )}
        <ul className={styles.menu}>{getDisplayMenu()}</ul>

        <ul className={styles.langMenu}>
          <li className={styles.langEl}>
            <Link to="/" activeClassName={styles.disableEl}>
              el
            </Link>
          </li>
          <li className={styles.langEn}>
            <Link
              to="/en"
              partiallyActive={true}
              activeClassName={styles.disableEn}
            >
              en
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
